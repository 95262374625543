.contact {
	background-color: #fff;
	padding: 11rem 0;
}

.contact__content {
	display: flex;
	flex-direction: column;
}

@media (max-width: 750px) {
	.contact__title {
		text-align: center;
	}
}

.contact__icons {
	display: flex;
	flex-wrap: wrap;
	gap: 8rem;
	margin-top: 6rem;
}

@media (max-width: 750px) {
	.contact__icons {
		flex-direction: column;
		gap: 5rem;
		justify-content: center;
		text-align: center;
	}
}

.contact__title p {
	color: #147efb;
	font-size: 1.7rem;
	font-weight: 900;
	margin-bottom: 1rem;
	text-transform: uppercase;
}

.contact__title h3 {
	color: #2d2e32;
	font-size: 2.5rem;
	font-weight: 700;
}

.contact__icon-box {
	align-items: center;
	display: flex;
	gap: 1.5rem;
}

@media (max-width: 750px) {
	.contact__icon-box {
		flex-direction: column;
	}
}

.contact__icon-box span {
	align-items: center;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0 0 10px rgba(0,0,0,.1);
	color: #147efb;
	display: flex;
	height: 5rem;
	justify-content: center;
	width: 5rem;
}

.contact__info {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.contact__info h3 {
	color: #2d2e32;
	font-size: 1.7rem;
	font-weight: bold;
}

.contact__info a, .contact__info p {
	color: #767676;
	cursor: pointer;
	font-size: 1.7rem;
	text-decoration: none;
	font-weight: 600;
}

.contact__info a:hover,
.contact__info p:hover {
  color: #147efb;
  transition: all 0.3s;
}