.hero {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	padding-bottom: 30px;
	background-color: #f9f9f9;
	position: relative;
}
@media (max-width: 900px) {
	.hero {
		height: auto;
		padding: 11rem 0;
	}
}

.hero-main {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10rem;
	position: relative;
	padding-bottom: 50px;
}
@media (max-width: 900px) {
	.hero-main {
		flex-direction: column-reverse;
		text-align: center;
		height: auto;
		gap: 3rem;
		padding-bottom: 0;
	}
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90rem;
	gap: 10rem;
	position: relative;
}
@media (max-width: 900px) {
	.content {
		flex-direction: column;
		text-align: center;
		height: auto;
		gap: 3rem;
	}
}
@media (min-width: 1600px) {
	.content {
		margin-top: 6rem;
	}
}
@media (min-width: 1900px) {
	.content {
		margin-top: 10rem;
	}
}

.hero-text {
	display: flex;
	flex-direction: column;
	max-width: 50rem;
	position: relative;
}
.hero-text h1 {
	font-size: 5.5rem;
	line-height: 1.2;
	color: #2d2e32;
	margin-bottom: 2rem;
	margin-top: 2rem;
}
@media (max-width: 500px) {
	.hero-text h1 {
		font-size: 4rem;
	}
}
.hero-text p {
	font-size: 1.8rem;
	font-weight: 500;
	color: #555555;
	font-family: 'Mulish', sans-serif;
	line-height: 1.6;
}
@media (max-width: 900px) {
	.hero-text p {
		margin-bottom: 5rem;
	}
}
.hero-text span {
	display: flex;
	gap: 1.3rem;
	margin: 2.5rem 0;
	cursor: pointer;
}
.hero-text span a {
	font-size: 3rem;
	color: #2d2e32;
}
@media (max-width: 900px) {
	.hero-text span {
		justify-content: center;
		margin-top: -2rem;
		margin-bottom: 4rem;
		gap: 1.6rem;
	}
}
.hero-text span a {
	transition: all 0.2s;
}
.hero-text span a:hover {
	color: #147efb;
}
.hero-text img {
	position: absolute;
	width: 6rem;
	height: 6rem;
	top: 8.7rem;
	right: 8rem;
}
@media (max-width: 900px) {
	.hero-text img {
		right: -2rem;
	}
}
@media (max-width: 500px) {
	.hero-text img {
		top: 6.4rem;
		right: -1rem;
		width: 4.5rem;
		height: 4.5rem;
	}
}
@media (max-width: 400px) {
	.hero-text img {
		right: 3rem;
	}
}
@media (max-width: 380px) {
	.hero-text img {
		right: 3rem;
	}
}
@media (max-width: 375px) {
	.hero-text img {
		right: 1.7rem;
	}
}

.hero-img {
	background-image: url('/public/hero.jpeg');
	width: 30rem;
	height: 30rem;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border: 3px solid #2d2e32;
	animation: morph 8s ease-in-out infinite;
	border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
	position: relative;
	transition: all 1s ease-in-out;
}

@keyframes morph {
	0% {
		border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
	}

	50% {
		border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
	}

	100% {
		border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
	}
}

@media (max-width: 500px) {
	.hero-img {
		width: 25rem;
		height: 25rem;
	}
}

.skills {
	align-items: center;
	bottom: 0;
	color: #767676;
	display: flex;
	font-size: 1.7rem;
	left: 0;
	position: absolute;
}

@media (max-width: 900px) {
	.skills {
		flex-direction: column;
		position: static;
	}
}

.skills p {
	border-right: 2px solid rgba(45, 46, 50, 0.5);
	color: #2d2e32;
	font-family: Mulish, sans-serif;
	font-weight: 600;
	margin-right: 7rem;
	padding-right: 2rem;
}

.skills ul {
	display: flex;
	flex-wrap: wrap;
	gap: 3rem;
	list-style: none;
}

.skills ul li {
	cursor: pointer;
}

.skills ul li img {
	height: 5rem;
	transition: all 0.3s ease-in-out;
	width: 11rem;
}

.skills ul li img:hover {
	transform: translateY(-1rem);
}

@media (max-width: 900px) {
	.skills p {
		border-bottom: 2px solid rgba(45, 46, 50, 0.5);
		border-right: none;
		margin-bottom: 3rem;
		margin-right: 0;
		padding-bottom: 1rem;
		padding-right: 0;
	}
	.skills ul {
		justify-content: center;
	}
}

.icons {
	display: flex;
	flex-flow: row wrap;
	direction: 4tr;
	justify-content: center;
}