/* $background-color: white;
$gray-bg-color: #f9f9f9;
$title-color: black;
$text-color: #767676;
$header-color: #147efb;
$black-shade: #2d2e32; */


body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: Poppins,sans-serif !important;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth
}

body {
  background-color: #fff;
  font-family: Poppins,sans-serif;
  position: relative
}

.container {
  margin: 0 auto;
  max-width: 107rem;
  padding: 0 4rem;
}

* {
  scrollbar-color: #8e8e8e #fff;
  scrollbar-width: thin
}

::-webkit-scrollbar {
  width: 8px
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 20px
}

::-webkit-scrollbar-track:active,::-webkit-scrollbar-track:hover {
  background-color: #fff
}

::-webkit-scrollbar-thumb {
  background-color: #8e8e8e;
  border-radius: 20px
}

::-webkit-scrollbar-thumb:hover {
  background-color: #878787
}

::-webkit-scrollbar-thumb:active {
  background-color: #8e8e8e
}