nav {
	align-items: center;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
	display: flex;
	font-size: 1.7rem;
	height: auto;
	justify-content: space-between;
	left: 0;
	padding: 25px 40px 25px 50px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 200;
}

.logo {
	color: rgb(45, 46, 50);
	cursor: pointer;
	margin-bottom: 0;
}

nav ul {
  display: flex;
  gap: 2rem;
  list-style: none;
}

@media (max-width: 900px) {
  nav ul a {
    display: none;
  }
}

nav ul a {
  color: #2d2e32;
  font-weight: 600;
  text-decoration: none;
  transition: all .23s;
}
nav ul a:hover {
  color: #147efb;
}

.nav-link {
	color: #2d2e32;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.23s;
}

.mobile-menu {
	font-size: 2.3rem;
	cursor: pointer;
	display: none;
}

.switcherM {
	display: none;
	margin-top: 100px;
	flex-direction: column;
}

.switcherM select {
  width: 85px;
  height: 30px;
	border: 2px solid #dfe4f996;
	border-radius: 5px;
}

.mobile-menu:hover {
	color: #147efb;
	transition: all 0.3s;
}
@media (max-width: 900px) {
	.mobile-menu {
		display: flex;
	}
	.switcherM {
		display: flex;
	}
}

.closed-menu {
	left: -100% !important;
}

.open-menu {
	left: 0 !important;
}

.mobile-nav {
	display: flex;
	width: 100%;
	height: 100vh;
	z-index: 300;
	background-color: white;
	position: fixed;
	top: 0;
	left: 0;
	text-align: center;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
	flex-direction: column;
}
.mobile-nav span {
	font-size: 3.3rem;
	position: absolute;
	top: 2rem;
	right: 4rem;
	cursor: pointer;
}
.mobile-nav span:hover {
	color: #147efb;
	transition: all 0.3s;
}
.mobile-nav ul {
	display: flex;
	flex-direction: column;
	gap: 4rem;
	font-size: 2.3rem;
	list-style: none;
}
.mobile-nav ul a {
	color: black;
	text-decoration: none;
	font-weight: 500;
}
.mobile-nav ul a:hover {
	color: #147efb;
	transition: all 0.3s;
}